import React from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
// import type { SessionStatus } from "./useSessionStatus";
import { type BaseData, getBaseData, getSessionStatus } from "Root/api";
import { AxiosError } from "axios";

export const queryKey = ["baseData"];
const UNKNOWN_SESSION_ERROR_MESSAGE = "unknwon session status"

export type SessionStatus = "active" | "inactive" | "unknown" | "fetching"

type UseSessionDataOptions = {
  onFetchStart?: () => void;
  onFetchFinish?: (error?: any) => void;
};

type UseSessionReturn = {
  data: BaseData | undefined;
  error: any;
  sessionStatus: SessionStatus;
  startSession: () => void;
  stopSession: () => void;
};

const useSessionManagement = ({ onFetchStart, onFetchFinish }: UseSessionDataOptions): UseSessionReturn => {
  const queryClient = useQueryClient();

  const { data: sessionStatusData, isFetching: isSessionStatusFetching, error: sessionStatusError} = useQuery({
    queryKey: ["session-status"],
    queryFn: getSessionStatus,
  });

  const sessionStatus = React.useMemo(() => {
    let status: SessionStatus = "fetching"

    if (sessionStatusError)
      status = "unknown";
    else if (!isSessionStatusFetching) {
      status = sessionStatusData!.session ? "active" : "inactive";
    }

    return status;
  }, [isSessionStatusFetching, sessionStatusData, sessionStatusError]);

  const { data, isFetching, error, refetch, } = useQuery({
    queryKey: queryKey,
    enabled: false,
    queryFn: async () => {
      onFetchStart?.();
      return getBaseData();
    },
  });

  React.useEffect(() => {
    if (!isFetching) {
      onFetchFinish?.(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isFetching]);

  const startSession = refetch;
  
  const stopSession = React.useCallback(() => {
    queryClient.setQueryData(queryKey, undefined);
    queryClient.clear();
  }, [queryClient]);

  React.useEffect(() => {
    if (sessionStatus === "active") {
      startSession();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStatus]);
  
  const sessionError = React.useMemo(() =>{
    let err = undefined;
  
    if (sessionStatus === "unknown")
      err = new Error(UNKNOWN_SESSION_ERROR_MESSAGE);
    if (sessionStatusError)
      err = new Error(UNKNOWN_SESSION_ERROR_MESSAGE);
    if (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status !== 401) {
          err = new Error(UNKNOWN_SESSION_ERROR_MESSAGE);
        }
      }
    }
    return err;
  }, [error, sessionStatus, sessionStatusError])


  return {
    data,
    sessionStatus,
    error: sessionError,
    startSession,
    stopSession,
  }
};

export default useSessionManagement;