import React, { Suspense, useCallback, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css"; // TODO implement in styled components
import "rc-tooltip/assets/bootstrap.css";
import "flexmonster/flexmonster.css";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import CookieConsentBar from "Main/CookieConsentBar";
import ErrorPlaceholder from "Shared/Placeholder/Error";
import useSession from "Shared/Session/use";
import FullFlex from "Shared/FullFlex";
import useLocalStorage from "Shared/hooks/useLocalStorage";

import "./initScripts";
import routes from "./routes";
import { translateError } from "./initScripts/initYup";
import useAxiosInterceptors from "./hooks/useAxiosInterceptors";
import useSentryUserIdentification from "./hooks/useSentryUserIdentification";
import DownloadMobileAppModal from "./DownloadMobileAppModal";
import useSessionManagement from "./hooks/useSessionManagement";

const SPLASH_SCREEN_ANIMATION_TIME = 3000;

const LazyAuthApp = React.lazy(() => import("../Auth"));
const LazyMainApp = React.lazy(() => import("../Main/App"));
const SplashScreen = React.lazy(() => import("./SplashScreen"));

const azureLoginContinuationPaths = [
  "auth/two-factor-activation",
  "auth/confirmation",
  "auth/two-factor",
];

const isAzureContinuation = () =>
  azureLoginContinuationPaths.some((path) =>
    window.location.pathname.includes(path),
  );

const StyledFullFlex = styled(FullFlex)`
  color: ${themeGet("colors.darkgrey")};
  font-family: ${themeGet("fonts.sans")};
  font-size: ${themeGet("fontSizes.medium")};
`;

export const baseDataQueryKey = "baseData";

const RootApp: React.FC = () => {
  const { t } = useTranslation();
  useAxiosInterceptors();

  useEffect(() => {
    translateError(t);
  }, [t]);

  const paramsString = new URLSearchParams(window.location.search);
  const [redirectUrl, _setRedirectUrl] = React.useState<string | null>(
    paramsString.get("redirect"),
  );

  const [splashScreenRunning, setSplashScreenRunning] =
    React.useState<boolean>(false);
  const [azureLoginContinuation, setAzureLoginContinuation] =
    React.useState<boolean>(isAzureContinuation()); // window.location.pathname.includes("auth/confirmation")
  const [consentedToCookies, setConsentedToCookies] = useLocalStorage(
    "cookieConsent",
    false,
  );

  const { data: baseData, sessionStatus, error, startSession, stopSession } = useSessionManagement({
    onFetchStart: () => {
      setSplashScreenRunning(true);
      setTimeout(() => {
        setSplashScreenRunning(false);
      }, SPLASH_SCREEN_ANIMATION_TIME);
    },
  });

  const { signInWith, azureLogoutRedirectMicrosoft } = useSession();

  useSentryUserIdentification(baseData?.user.id);

  const handleCookieConsent = useCallback((): void => {
    setConsentedToCookies(true);
  }, [setConsentedToCookies]);

  const handleLogin = useCallback((): void => {
    setAzureLoginContinuation(false);
    startSession();
    toast.success(t("auth.login-successful"));
  }, [startSession, t]);

  const handleLogout = useCallback(
    async (logoutErrorMessage?: string): Promise<void> => {
      if (signInWith === "AZURE" && azureLogoutRedirectMicrosoft) {
        window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${process.env.REACT_APP_API_ROOT_URL}/`;
      }
      stopSession();
      if (logoutErrorMessage) {
        setTimeout(() => toast.error(logoutErrorMessage), 10);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [signInWith, azureLogoutRedirectMicrosoft, stopSession],
  );
  
  if (error) {
    return <ErrorPlaceholder hideDashboardLink />;
  }

  if (splashScreenRunning && !azureLoginContinuation) {
    return <SplashScreen />;
  }
  
  return sessionStatus === "fetching" || (sessionStatus === "active" && !baseData) ? null : (
    <Suspense>
      <StyledFullFlex flexDirection="column">
        <DownloadMobileAppModal />
        <Switch>
          <Route strict path={routes.auth}>
            {baseData ? (
              <Redirect
                from={routes.auth}
                to={redirectUrl ? `/${redirectUrl}` : routes.main}
              />
            ) : (
              <LazyAuthApp onLogin={handleLogin} />
            )}
          </Route>
          <Route path={routes.main}>
            {baseData ? (
              <>
                <LazyMainApp onLogout={handleLogout} baseData={baseData} />
                {redirectUrl && (
                  <Redirect from={routes.main} to={`/${redirectUrl}`} />
                )}
              </>
            ) : (
              <Redirect from={routes.main} to={routes.auth} />
            )}
          </Route>
        </Switch>
        <ToastContainer closeOnClick position="bottom-left" theme="colored" />
        {!consentedToCookies && (
          <CookieConsentBar onConsent={handleCookieConsent} />
        )}
      </StyledFullFlex>
    </Suspense>
  );
};

export default RootApp;
